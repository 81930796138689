import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Authenticate from './Authenticate';
import ChooseUsernameForm from './ChooseUsernameForm';
import { useTheme } from '@mui/material';
import { navResetFields, useNavBarContext } from './NavBarContext';

export default function SignUpForm({setOpen, activeStep, setActiveStep, openTerms, openPrivacy, type}) {
  const theme = useTheme();
  const navDispatch = useNavBarContext()[1];

  useEffect(() => {
    if (activeStep === 0) {
      navDispatch(navResetFields);
    }
  }, [activeStep, navDispatch])

  //mui step label root margin top 0?

  const stepSx = { // overrides also done globally
    '& .MuiStepIcon-root.Mui-completed': {
      //background: 'radial-gradient(#000 60%, #fff 60%)',
      color: theme.palette.secondary.light,
      //backgroundColor: '#26A69A',
    },
    '& .MuiStepIcon-root.Mui-active': {
      //background: 'radial-gradient(#000 60%, #fff 60%)',
      color: theme.palette.secondary.light,
      //backgroundColor: '#26A69A',
    },
    '& .MuiStepIcon-text': {
      //background: 'radial-gradient(#000 60%, #fff 60%)',
      fill: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      //backgroundColor: '#26A69A',
    },
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /*const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }; */

  return (
    <Box sx={{ mb: 4, width:'min(82vw, 400px)'}}>
      {type === "signup" ?
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key='Choose username'>
            <StepLabel
              sx={stepSx}
              style={{paddingTop:0}}
            >Choose username</StepLabel>
            <StepContent>
              <ChooseUsernameForm
                handleSubmit={handleNext}
              />
            </StepContent>
          </Step>
          <Step key='Choose authentication method'>
            <StepLabel
              sx={stepSx}
            >Choose authentication method</StepLabel>
            <StepContent>
              <Authenticate
                openTerms={openTerms}
                openPrivacy={openPrivacy}
                type={type}
              />
            </StepContent>
          </Step>
        </Stepper>
      :
        <Box sx={{mt: 6}}>
          <Authenticate
            openTerms={openTerms}
            openPrivacy={openPrivacy}
            type={type}
          />
        </Box>
      }
    </Box>
  );
}