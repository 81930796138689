import { useEffect, useState } from 'react';
import SeedAvatar from './SeedAvatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { getCleanedUsername, isSelectedByTheUser } from '../utils/username';

export default function UserInfo() {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userSelected, setUserSelected] = useState(false);
  const [cleanedUsername, setCleanedUsername] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    const result = await auth.signout();
    if (!result.success) {
      console.info(`Log out error: ${result.errorReadable}`);
    }
  }

  useEffect(() => {
    const username = auth.user.displayName;
    if (username) {
      setUserSelected(isSelectedByTheUser(username));
      setCleanedUsername(getCleanedUsername(username));
    }
  }, [auth.user.displayName]);

  return (
    <>
      <div onClick={handleClick} style={{ width:'26px', height:'26px', margin:'auto', padding:'8px', backgroundColor: 'white', borderRadius:'50%'}}>
        {userSelected && <SeedAvatar seed={cleanedUsername} />}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        autoFocus={false}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{pl:2, pr:2}} style={{ pointerEvents: 'none', fontWeight:'bold' }} onClick={handleClose}>{cleanedUsername}</MenuItem>
        <Divider/>
        <MenuItem sx={{pl:2, pr:10}} onClick={() => handleLogout()}>Logout</MenuItem>
      </Menu>
    </>
  );
}