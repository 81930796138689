import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { ReactComponent as GoogleSvg } from '../image/google.svg';

const GoogleButton = ({handleClick}) => {
  const [loading, setLoading] = useState(false);
  const handleClickWrapper = () => {
    setLoading(true);
    handleClick();
  }
  return (
    <Box sx={{ width:'100%', textAlign: 'center'}}>
      <Button className="google-btn" onClick={handleClickWrapper}>
        {!loading ? 
        <>
          <span className="google-btn-text-wrapper">
            <GoogleSvg className="google-img" alt="google sign in"/>
          </span>
          <span className="google-btn-text google-btn-text-long">
            Sign in with Google
          </span>
          <span className="google-btn-text google-btn-text-short">
            Google
          </span>
        </> :
          <CircularProgress color="secondary"/>
        }
      </Button>
    </Box>
  );
}

export default GoogleButton;