import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SignUpForm from './SignUpForm';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ForgotPasswordModal from './ForgotPasswordModal';
import ModalBase from './ModalBase';
import { useTheme } from '@mui/material';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import { navResetFields, useNavBarContext } from './NavBarContext';


export default function LogModals() {
  const theme = useTheme();
  const navDispatch = useNavBarContext()[1];
  const [open, setOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [type, setType] = useState('');

  const handleLogin = (e) => {
    setType('signin')
    setOpen(true);
    e.currentTarget.blur();
  }
  const handleSignUp = (e) => {
    setType('signup')
    setOpen(true);
    e.currentTarget.blur();
  }
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setForgotOpen(false);
      setTermsOpen(false);
      setPrivacyOpen(false);
      setActiveStep(0);
    }, 500);
  }

  return (
    <>
      <Button color="inherit" variant="text" sx={{mr:2}} onClick={handleLogin}>Log in</Button>
      <Button color="secondary" variant="contained" onClick={handleSignUp}>Sign up</Button>
      <ModalBase open={open} onClose={handleClose}>
        { termsOpen && !privacyOpen && <TermsOfService back={() => setTermsOpen(false)}/>}
        { !termsOpen && privacyOpen && <PrivacyPolicy back={() => setPrivacyOpen(false)}/>}
        { !termsOpen && !privacyOpen && (!forgotOpen ?
          <>
            <SignUpForm
              setOpen={setOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              openTerms={() => {setTermsOpen(true); setActiveStep(1)}}
              openPrivacy={() => {setPrivacyOpen(true); setActiveStep(1)}}
              type={type}
            />
            <Box sx={{ width:'100%', textAlign: 'center'}}>
              <>
                {type !== "signup"  &&
                  <Link
                    component="button"
                    variant="body2"
                    display="block"
                    sx={{mb:1, margin:'5px auto'}}
                    color={theme.palette.secondary.light}
                    onClick={() => {
                      setOpen(false)
                      setTimeout(() => {
                        setForgotOpen(true);
                        setOpen(true);
                      }, 300);
                    }}
                  >
                  Forgot password?
                  </Link>
                }
              </>
              <Typography sx={{color: 'rgba(0, 0, 0, 0.36)'}} variant="body2" display="inline">
                {type === "signup" ?
                "Already have an account? " :
                "Don’t have an account? "}
              </Typography>
              <Link
                component="button"
                variant="body2"
                color={theme.palette.secondary.light}
                onClick={() => {
                  setOpen(false);
                  navDispatch(navResetFields());
                  setActiveStep(0);
                  setTimeout(() => {
                    if (type === "signup") {
                      setType("login")
                    }else{
                      setType('signup')
                    }
                    setOpen(true);
                  }, 300);
                }}
              >
                {type === "signup" ?
                "Log in" :
                "Sign up" }
              </Link>
            </Box>
          </>
        :
          <ForgotPasswordModal handleClose={handleClose}/>
        )}
      </ModalBase>
    </>
  );
}