import Box from '@mui/material/Box';
import { useState, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, Typography } from '@mui/material';
import ModalBase from './ModalBase';
import { useAuth } from '../hooks/useAuth';

const WaitingEmailVerificationModal = () => {
  const auth = useAuth();
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() =>{
    setOpen(false);
  }, [])

  return (
    <ModalBase open={open} onClose={handleClose} forceOpen={true}>
      <Box sx={{width:'min(75vw, 300px)', mt: 3, mb: 3, textAlign: 'center'}}>
        {auth.checkingVerification && <CircularProgress color="secondary"/>}
        <Typography sx={{mt:2, color:'rgba(0, 0, 0, 0.55)'}} >Click 'verify' from your email</Typography>
        {auth.user && auth.user.email &&
          <Typography sx={{mb:4, color:'rgba(0, 0, 0, 0.55)'}} >{auth.user.email}</Typography>
        }
        {!auth.checkingVerification && <Typography sx={{mb:4, color:'rgba(0, 0, 0, 0.55)'}} >Then refresh this page</Typography>}
        <Link
          component="button"
          variant="body2"
          onClick={async() => {
            const result = await auth.authSendEmailVerification(auth.user);
            if (!result.success) {
              console.info(`Resend error: ${result.errorReadable}`);
            }
          }}
          color="secondary"
        >
          Resend verification email
        </Link>
        <br/>
        <Link
          component="button"
          variant="body2"
          color="secondary"
          onClick={async () => {
            const result = await auth.signout();
            if (!result.success) {
              console.info(`Log out error: ${result.errorReadable}`);
            }
          }}
        >
          Log out
        </Link>
      </Box>
    </ModalBase>
  )
}

export default WaitingEmailVerificationModal;