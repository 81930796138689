import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const markdown = `# FirebaseAuth demo

This is a demo page of my custom authentication and storage solution build with:

- [React](https://reactjs.org/) for structure and [hooks](https://reactjs.org/docs/hooks-reference.html#usecontext) for state handling
- Firebase [auth](https://firebase.google.com/docs/auth), [firestore](https://firebase.google.com/docs/firestore) and [cloud functions](https://firebase.google.com/docs/functions) as the backend
- [Typescript](https://www.typescriptlang.org/) for cathing bugs before they happen
- [MUI](https://mui.com/) for component styling
- [validator.js](https://github.com/validatorjs/validator.js) for data validation for front end and back end
- [Cloudflare Pages](https://pages.cloudflare.com/) for hosting

This solution is fully build into the top navigation bar.

## Trying it our

Currently there is not much to try here as you cannot post or interact with content. But if you want to try
this you could use temporary [10 minute email](https://www.minuteinbox.com/) and try creating potentially multiple accounts
here. I'll might delete these accounts at any time as this is just a tech demo.

## Motivation

The purpose of this project is to be a solid base for my projects that require authentication.

[Firebase auth](https://firebase.google.com/docs/auth) has a lot of nice features, but none of the existing
UI solutions did everything I wanted or they did not handle all of the edge cases properly. So I came up with this.

## Features

- Email + password and Google sigin support
- Unique username support (handles the case where two users create account with the same username at the same time)
- Proper UI with mobile support and animated transitions
- Autogenerated avatar
- Login / Logout
- Forced email verification
- Forgot password reset through email
- Easy access to Firestore database
- Data validation bot in the front end and backend

## Usage

For the time being I've decided to keep this project close sourced, and this web page is mainly a part of my project
portfolio and inspiration for others.  

But this is how I use it in my projects:
The first step is to create a new Firebase app, and call:

~~~jsx
npx create-react-app my-app-name
~~~

Then copy the \`AuthBar\` component source,
and import + configure it in App.tsx/.jsx:

~~~jsx
// This imports the top authentication bar seen on this website also
import AuthBar from './AuthBar';

// Paste Firebase configuration here after creating app from the dashboard
const firebaseConfig = {
  // apiKey: ...
};

// These colors will override to React Mui style library's default theme
// (https://mui.com/), and will set the style for the website
const palette = {
  primary: {
    main: '#FFFFFF',
    light: '#EFF2F3',
    dark: '#A5A7A8',
    contrastText: '#212121',
  },
  secondary: {
    main: '#26A69A',
    light: '#51B7AE',
    dark: '#1A746B',
    contrastText: '#FFFFFF',
  },
};

// If supplied, these generate:
// - GDPR compliant Privacy Policy
// - Terms of Service
const legal = {
  companyName: 'COMPANY_NAME',
  websiteName: 'WEBSITE_NAME',
  websiteUrl: 'WEBSITE_URL',
  companyEmail: 'COMPANY_EMAIL'
};


const App = () => {
  return (
    <AuthBar
      title='FirebaseAuth'
      legal={legal}
      firebaseConfig={firebaseConfig}
      palette={palette}
    >
      {
        // Website content here! A custom useAuth() hook gives
        // access to authentication state and functions
      }
    </AuthBar>
  );
}

export default App;
~~~

Copy \`functions\` directory to the root, and call:

~~~js
firebase deploy
~~~

Which handles the deployment of the cloud functions and database security rules.

## Future

In the future I'd like to package this into a proper npm package, but as this is currently only used for
personal use, manually copying sources is fine.

Also logging in with username + password instead of email + password would be a nice feature, but for now on this is sufficient

## My other projects:

These are open sourced:
- [classified.html](https://classifiedhtml.com/) ([github](https://github.com/ollipal/classified.html)) Portable offline
encryption solution that stores everything into a single .html file, which can be accessed from terminal and browser
- [sshkeyboard](https://sshkeyboard.readthedocs.io/) ([github](https://github.com/ollipal/sshkeyboard)) The only keyboard event
callback library that works everywhere, even when used through an SSH connection (hence the name). Documentation build process and running tests/formatters is 100% automated
- [minimal-password-prompt](https://www.npmjs.com/package/minimal-password-prompt) ([github](https://github.com/ollipal/minimal-password-prompt))
the most light weight solution for getting secret user input through terminal in NodeJS

## Me

[github](https://github.com/ollipal)
[linkedin](https://www.linkedin.com/in/ollipaloviita/)

`;

const Markdown = () => {
  return (
    <ReactMarkdown
      className="markdown"
      children={markdown}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              style={a11yDark}
              language={match[1]}
              wrapLongLines
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
    />
/*     <SyntaxHighlighter
      language='jsx'
      style={a11yDark}
      wrapLongLines
    >
      {}
    </SyntaxHighlighter> */
  );
};

export default Markdown;