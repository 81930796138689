import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { isTouchDevice } from '../utils/device';
import MultilineHelperText from './MultilineHelperText';
import { setUsername, useNavBarContext, usernameNotAvailable } from './NavBarContext';
import SeedAvatar from './SeedAvatar';

export default function ChooseUsernameForm({handleSubmit}) {
  const auth = useAuth();
  const [navState, navDispatch] = useNavBarContext();
  const [canShowErrors, setCanShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => { 
    setCanShowErrors(
      navState.username.error
    )
  }

  const handleSubmitWrapper = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (await auth.usernameAvailable(navState.username.value)) {
      await handleSubmit();
    } else {
      setLoading(false);
      navDispatch(usernameNotAvailable()); 
    }
  }

  useEffect(() => {
    setCanShowErrors(
      navState.username.hasBeenPotentiallyValid &&
      navState.username.error
    )
  }, [navState.username]);

  return (
    <>
      <Box sx={{ mt: 3, mb: 3, width:'100%', textAlign: 'center'}}>
        <div style={{ width:'30%', height:'30%', margin:'auto', padding:'1%', backgroundColor: 'white' /* 'rgb(224,224,224)' */, borderRadius:'50%'}}>
          <SeedAvatar seed={navState.username.value}/>
        </div>
      </Box>
      <Box sx={{ mt: 3, width:'100%', textAlign: 'center'}}>
        <form onSubmit={handleSubmitWrapper} style={{autocomplete: "off"}}>
          <TextField
            sx={{autoComplete: "off", width: '26ch'}}
            autoFocus={!isTouchDevice()}
            onChange={(event) => navDispatch(setUsername(event.target.value))}
            id={(Math.random() + 1).toString(36).substring(7)} // to stop suggetions
            label="Username"
            spellCheck="false"
            autoComplete="off"
            color="secondary"
            error={canShowErrors}
            helperText={canShowErrors &&
              <MultilineHelperText texts={navState.username.messages} />
            }
          />
          <Box sx={{ mb: 2, mt: 2 }}>
            <div onClick={handleButtonClick}>
              <LoadingButton
                disabled={navState.username.value === '' || navState.username.error || !navState.username.hasBeenPotentiallyValid}
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ mt: 1 }}
                loading={loading}
                >
                {'Continue'}
              </LoadingButton>
            </div>
          </Box>
        </form>
        <br/>
        <Link
          component="button"
          variant="body2"
          color="secondary"
          onClick={async () => {
            const result = await auth.signout();
            if (!result.success) {
              console.info(`Log out error: ${result.errorReadable}`);
            }
          }}
        >
          Log out
        </Link>
      </Box>
    </>
  );
}