import { useState, useEffect, useContext, createContext, useRef, useMemo, useCallback } from "react";
import { initializeApp } from "firebase/app";
// https://usehooks.com/useAuth/
import { // https://firebase.google.com/docs/auth/web/manage-users#set_a_users_email_address
  getAuth,
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut,
  sendPasswordResetEmail,
  //verifyPasswordResetCode, // TODO?????
  confirmPasswordReset, // https://firebase.google.com/docs/auth/custom-email-handler
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth"

import { getFirestore } from "firebase/firestore"
import { setDoc, getDoc, doc } from "firebase/firestore"; 

import { convertNotUnique, convertUnique, createNotCheckedUsername, getCleanedUsername, isNotChecked, isSelectedByTheUser } from "../utils/username";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const authContext = createContext();
const provider = new GoogleAuthProvider();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children, firebaseConfig }) {
  const auth = useProvideAuth(firebaseConfig);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

const capitalizeFirstLetter = (str) => {
  return str[0].toUpperCase() + str.slice(1);
}

const toReadable = (str) => {
  return capitalizeFirstLetter(str.replace(/^(auth\/)/,"").replace(/-/g,' '))
}

// Provider hook that creates auth object and handles state
function useProvideAuth(firebaseConfig) {
  const [user, setUser] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [checkingVerification, setCheckingVerification] = useState(false);
  const verificationRef = useRef({interval: null, unsubscribe: null});
  // sometimes logout does not seem to work
  // might be just react reloading but let's add a double check
  const canStartEmailVerfication = useRef(true);

  // Initialize Firebase
  const app = useMemo(() => initializeApp(firebaseConfig), [firebaseConfig]);
  const auth = useMemo(() => getAuth(app), [app]);
  const db = useMemo(() => getFirestore(app), [app]);

  const reserveUsername = useCallback(async (username) => {
    try {
      await setDoc(doc(db, "users", auth.currentUser.uid), { username });
      return true;
    } catch (e) {
      console.error("Error adding document: ", e);
      return false;
    }
  }, [auth.currentUser, db])

  const usernameAvailable = useCallback(async (username) => (
    !(await getDoc(doc(db, "usernames", username))).exists()
  ), [db])

  const updateDisplayName = useCallback(async (displayName) => {
    try {
      await updateProfile(auth.currentUser, {displayName});
      setUser({...auth.currentUser, displayName}); // otherwise does not update
      auth.currentUser.reload();
    } catch (error) {
      console.log(`Profile update error: ${error}`);
    }
  }, [auth.currentUser])

  const signin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Signed in 
      const user = userCredential.user;
      setUser(user);
      return {
        user,
        errorCode: null,
        errorMessage: null,
        errorReadable: null
      };
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      setUser(null);
      return {
        user: null,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    }
  };

  const signinWithGoogle = async () => {
    try {
      await signInWithRedirect(auth, provider);
      return true;
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      setUser(null);
      return {
        user: null,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    };
  }

  const signup = async (email, password, username) => {
    canStartEmailVerfication.current = true;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUser(user);
      await updateDisplayName(
        createNotCheckedUsername(username)
      )
      await checkUsername(username)
      //await authSendEmailVerification(); this does not work
      return {
        user,
        errorCode: null,
        errorMessage: null,
        errorReadable: null
      };
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      setUser(null);
      return {
        user: null,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    }
  };

  const signout = async () => {
    canStartEmailVerfication.current = false;
    try {
      await signOut(auth);
      setUser(null);
      return {
        success: true,
        errorCode: null,
        errorMessage: null,
        errorReadable: null
      };
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      return {
        success: false,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    }
  };

  const authSendPasswordResetEmail = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email)
      return {
        success: true,
        errorCode: null,
        errorMessage: null,
        errorReadable: null
      };
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      return {
        success: false,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    };
  };

  const authConfirmPasswordReset = async (code, password) => {
    try {
      await confirmPasswordReset(auth, code, password);
      return {
        success: true,
        errorCode: null,
        errorMessage: null,
        errorReadable: null
      };
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      return {
        success: false,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    }
  };

  const authSendEmailVerification = async (user) => {
    try {
      await sendEmailVerification(user);
      return {
        success: true,
        errorCode: null,
        errorMessage: null,
        errorReadable: null
      };
    } catch (error) {
      setErrorCode(error.code);
      setErrorMessage(error.message);
      return {
        success: false,
        errorCode: error.code,
        errorMessage: error.message,
        errorReadable: error.code ? toReadable(error.code) : error.message
      };
    }
  };

  const checkUsername = useCallback(async (username) => {
    const success = await reserveUsername(getCleanedUsername(username));
    if (success) {
      await updateDisplayName(convertUnique(username))
    } else {
      await updateDisplayName(convertNotUnique(username))
    }
  }, [reserveUsername, updateDisplayName])

  useEffect(() => {
    const stopEmailVerificationInterval = () => {
      setCheckingVerification(false);
      if (verificationRef.current.interval) {
        clearInterval(verificationRef.current.interval);
      }
      if (verificationRef.current.unsubscribe) {
        verificationRef.current.unsubscribe();
      }
    }

    const startEmailVerificationInterval = () => {
      stopEmailVerificationInterval();
  
      if (!canStartEmailVerfication.current) {
        console.log("Cannot start email verification: logged out");
        return;
      }

      setCheckingVerification(true);
  
      verificationRef.current.unsubscribe = onIdTokenChanged(auth, (reloadedUser) => {
        if (reloadedUser && reloadedUser.emailVerified) {
          stopEmailVerificationInterval();
          setUser({...reloadedUser});
        }
      });
  
      let i = 1;
      verificationRef.current.interval = setInterval(() => {
        if (i >= 20 || !canStartEmailVerfication.current) {
          stopEmailVerificationInterval();
          return
        }

        if (auth.currentUser) {
          console.log("checking email verification...");
          auth.currentUser.reload();
        } else { // logged out
          stopEmailVerificationInterval();
        }
  
        i++;
      }, 3000);
    };

    const authObserverUnsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        //console.log(user);
        const username = user.displayName;
        if(
          isSelectedByTheUser(username) &&
          isNotChecked(username))
        {
          await checkUsername(username);
        }

        if (
          !user.emailVerified // TODO this is now happening even if reselcting username
        ) {
          startEmailVerificationInterval();
        } else {
          stopEmailVerificationInterval();
        }
      } else {
        setUser(null);
        stopEmailVerificationInterval();
      }
    });

    // Cleanup subscription on unmount
    return () => {
      stopEmailVerificationInterval();
      authObserverUnsubscribe();
    }; // TODO test does this work!!!
  }, [auth, checkUsername, updateDisplayName]);

  // Return the user object and auth methods
  return {
    user,
    errorCode,
    errorMessage,
    checkingVerification,
    signin,
    signup,
    signout,
    authSendPasswordResetEmail,
    authConfirmPasswordReset,
    authSendEmailVerification,
    signinWithGoogle,
    updateDisplayName,
    checkUsername,
    usernameAvailable,
  };
}