//import { isUniqueUsername } from "./inputValidator";
const NOT_CHECKED_BEGINNING = "__NOT_CHECKED_USERNAME!#*"
const NOT_UNIQUE_BEGINNING = "__NOT_UNIQUE_USERNAME!#*"
const UNIQUE_BEGINNING = "__UNIQUE_USERNAME!#*"

export const isQuaranteedUnique = (username : string | undefined | null) : boolean => {
  if (isUnknown(username)) {
    return false;
  }
  return isUnique(username as string);
}

export const isSelectedByTheUser = (username : string | undefined | null) : boolean => {
  return !isUnknown(username);
}

const isUnknown = (username : string | undefined | null) : boolean => (
  username === null ||
  username === undefined ||
  (
    !isNotChecked(username) &&
    !isUnique(username) && 
    !isNotUnique(username)
  )
)

export const createNotCheckedUsername = (username : string) => (
  `${NOT_CHECKED_BEGINNING}${username}`
)

export const convertUnique = (username : string) => (
  `${UNIQUE_BEGINNING}${getCleanedUsername(username)}`
)

export const convertNotUnique = (username : string) => (
  `${NOT_UNIQUE_BEGINNING}${getCleanedUsername(username)}`
)


export const isNotChecked = (username : string )=> (
  username.startsWith(NOT_CHECKED_BEGINNING)
)

export const isUnique = (username : string) => (
  username.startsWith(UNIQUE_BEGINNING)
)

export const isNotUnique = (username : string) => (
  username.startsWith(NOT_UNIQUE_BEGINNING)
)

export const getCleanedUsername = (username : string) => {
  if (isNotChecked(username)) {
    username = username.replace(NOT_CHECKED_BEGINNING, '');
  }
  if (isUnique(username)) {
    username = username.replace(UNIQUE_BEGINNING, '');
  }
  if (isNotUnique(username)) {
    username = username.replace(NOT_UNIQUE_BEGINNING, '');
  }
  return username;
}