import 'typeface-roboto'
import './AuthBar.css';
import { ProvideAuth } from "./hooks/useAuth";
import NavBar from './components/NavBar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from "react";
import { ProvideNavBarContext } from './components/NavBarContext';

const components = {
  MuiStepIcon: {
    styleOverrides: {
      root: {
        fontWeight: 'bold', // match completed thickness
        backgroundColor: 'inherit', // fix weird svg sides on Firefox
        borderRadius: '50%', // fix weird svg sides on Firefox
      },
    },
  },
}

const defaultPalette = {
  primary: {
    main: '#ECEFF1',
    light: '#EFF2F3',
    dark: '#A5A7A8',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    main: '#26A69A',
    light: '#51B7AE',
    dark: '#1A746B',
    contrastText: '#FFFFFF',
  },
};

const AuthBar = ({children, firebaseConfig, legal, title, palette=defaultPalette}) => {
  const theme = useMemo(() => createTheme({palette, components}), [palette])

  return (
    <ProvideAuth firebaseConfig={firebaseConfig}>
      <ThemeProvider theme={theme}>
        <ProvideNavBarContext legal={legal}>
          <NavBar title={title}/>
        </ProvideNavBarContext>
        {children}
      </ThemeProvider>
    </ProvideAuth>
  );
}

export default AuthBar;