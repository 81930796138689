import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { useCallback, useEffect } from 'react';
import { navResetFields, useNavBarContext } from './NavBarContext';

const style = {
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  overflow:'scroll',
  maxWidth: 'calc(99% - 2 * var(--modal-base-padding))',
  maxHeight: 'calc(99% - 2 * var(--modal-base-padding))',
};

export default function ModalBase({open, onClose, forceOpen=false, children}) {
  const navDispatch = useNavBarContext()[1];
  const onCloseWrapper = useCallback((_, reason) => {
    if (forceOpen && (reason === "escapeKeyDown" || reason === "backdropClick")) {
      return
    }
    onClose();
  }, [forceOpen, onClose])

  useEffect(() => {
    if (!open) {
      navDispatch(navResetFields());
    }
  },[navDispatch, open])
  
  return (
    <Modal
      open={open}
      onClose={onCloseWrapper}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <div className='app-bar-modal-base' style={style}>
          { !forceOpen &&
            <div className='app-bar-modal-close' style={{position:"absolute", top: 0, right: 0 }}>
              <IconButton
                  aria-label="close menu"
                  onClick={onCloseWrapper}
                  tabIndex={-1}
              >
                  <Close fontSize='small'/>
              </IconButton>
            </div>
          }
          {children}
        </div>
      </Fade>
    </Modal>
  );
}