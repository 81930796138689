import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Link, Typography, useTheme } from '@mui/material';
import MultilineHelperText from './MultilineHelperText';
import GoogleButton from './GoogleButton';
import { setEmail, setPassword, setStatus, useNavBarContext } from './NavBarContext';

const Authenticate = ({openTerms, openPrivacy, type}) => {
  const auth = useAuth();
  const [navState, navDispatch] = useNavBarContext();
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);
  const [canShowEmailErrors, setCanShowEmailErrors] = useState(false);
  const [canShowPasswordErrors, setCanShowPasswordErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setCanShowEmailErrors(true);
    setCanShowPasswordErrors(true);
    return () => {
      navDispatch(setStatus("idle"));
    }
  }, [navDispatch]);

  const handleEmail = (e) => {
    navDispatch(setEmail(e.target.value));
    setSubmitError('');
  }

  const handlePassword = (e) => {
    navDispatch(setPassword(e.target.value));
    setSubmitError('');
  }

  const handleEmailFocus = () => {
    setCanShowPasswordErrors(true);
    setCanShowEmailErrors(false);
  }

  const handlePasswordFocus = () => {
    setCanShowPasswordErrors(false);
    setCanShowEmailErrors(true);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleButtonClick = () => { // does not submit!
    setCanShowPasswordErrors(true);
    setCanShowEmailErrors(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleButtonClick();
    if (navState.email.error || navState.password.error) {
      return;
    }
    let result;
    setLoading(true);
    navDispatch(setStatus("checkingUsername"));
    if (type === "signup") {
      result = await auth.signup(navState.email.value, navState.password.value, navState.username.value);
    } else {
      result = await auth.signin(navState.email.value, navState.password.value);
    }
    if (result.user === null){
      console.log(`Logging error: ${result.errorCode}`);
      setSubmitError(result.errorReadable);
      setLoading(false);
    } else if (type === "signup"){
      // here the form has already closed as user has been created
      // and isSelectedByTheUser(username) === true
      const result2 = await auth.authSendEmailVerification(result.user);
      if (!result2.success){
        console.log(`Verify email send fail: ${result2.errorCode}`);
      }
    }
    console.log("idle")
    navDispatch(setStatus("idle"));
  }

  return (
    <>
      <Box sx={{ mt: 3, mb: 2 }}>
        <div style={{width:'26ch', margin:'auto'}}>
          <GoogleButton
            handleClick={async () => {
              const result = await auth.signinWithGoogle();
              if (!result){
                console.log(`Signin error: ${result.errorCode}`);
                setSubmitError(result.errorReadable);
                setLoading(false);
              }
            }}
          />
        </div>
      </Box>
      <Divider sx={{ mt: 3, mb: 3, pr: 5, pl: 5, fontFamily: 'Roboto,Helvetica,Arial,sans-serif'}}>
        <Typography sx={{color: 'rgba(0, 0, 0, 0.85)'}} variant="body1">or</Typography>
      </Divider>
      <Box sx={{ width:'100%', textAlign: 'center'}}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              sx={{ width: '26ch' }}
              id="outlined-required"
              label="Email"
              spellCheck="false"
              onFocus={handleEmailFocus}
              onChange={handleEmail}
              value={navState.email.value}
              error={canShowEmailErrors && navState.email.error}
              color="secondary"
              helperText={
                canShowEmailErrors && navState.email.error &&
                <MultilineHelperText texts={navState.email.messages} />
              }
            />
            <Box sx={{ mt: 2, mb: 2, width: '100%' }}>
              <TextField
                sx={{ width: '26ch' }}
                id={type === "signup" ? (Math.random() + 1).toString(36).substring(7) : "password"} // to stop suggetions
                autoComplete={type === "signup" ? "new-password" : "on"}
                label='Password'
                spellCheck="false"
                type={showPassword ? 'text' : 'password'}
                value={navState.password.value}
                onFocus={handlePasswordFocus}
                onChange={handlePassword}
                color="secondary"
                error={canShowPasswordErrors && navState.password.error}
                helperText={
                  canShowPasswordErrors && navState.password.error &&
                  <MultilineHelperText texts={navState.password.messages} />
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        /* onMouseDown={handleMouseDownPassword} */
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <div>
              <div onClick={handleButtonClick}>
                <LoadingButton
                  disabled={navState.email.error || navState.password.error || navState.email === '' || navState.password === ''}
                  variant="contained"
                  sx={{ mt: 1, mb: 2}}
                  type="submit"
                  color={submitError !== '' ? "error" : "secondary"}
                  loading={loading}
                >
                {type === "signup" ? "Sign Up" : "Log in"}
                </LoadingButton>
                {submitError !== '' && <Typography color="error">{submitError}</Typography>}
                {navState.legal !== null && type === "signup" &&
                  <>
                    <br/>
                    <Typography sx={{color: 'rgba(0, 0, 0, 0.36)'}} variant="body2" display="inline">
                      {"By continuing, you are indicating that you accept our "} 
                    </Typography>
                    <Link
                      component="button"
                      variant="body2"
                      color={theme.palette.secondary.light}
                      onClick={(e) => {e.preventDefault(); openTerms()}}
                    >
                      Terms of Service
                    </Link>
                    <Typography sx={{color: 'rgba(0, 0, 0, 0.36)'}} variant="body2" display="inline">
                      {" and "}
                    </Typography>
                    <Link
                      component="button"
                      variant="body2"
                      color={theme.palette.secondary.light}
                      onClick={(e) => {e.preventDefault(); openPrivacy()}}
                    >
                      Privacy Policy
                    </Link>
                  </>
                }
              </div>
            </div>
        </form>
      </Box>
    </>
  )
}

export default Authenticate;