import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogModals from './LogModals';
import UserInfo from './UserInfo';
import WaitingEmailVerificationModal from './WaitingEmailVerificationModal';
import SelectUsernameModal from './SelectUsernameModal';
import { useAuth } from '../hooks/useAuth';
import { useMemo } from 'react';
import { isNotUnique, isSelectedByTheUser, isUnique } from '../utils/username';
import { useNavBarContext } from './NavBarContext';

export default function NavBar({title}) {
  const auth = useAuth();
  const navState = useNavBarContext()[0];

  const username = useMemo(() => {
    if (auth.user && auth.user.displayName){
      return auth.user.displayName;
    } else {
      return null
    }
  }, [auth.user]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* handle username not selected (google sigin directly) */}
      {navState.status === "idle" &&  auth.user && !isSelectedByTheUser(username) && <SelectUsernameModal/>}
      {/* handle username checked and not unique */}
      {navState.status === "idle" &&  auth.user && isSelectedByTheUser(username) && isNotUnique(username) && <SelectUsernameModal type={"reselect"}/>}
      {/* handle username is unique but email has not been verified (google signin is always verified) */}
      {navState.status === "idle" &&  auth.user && isSelectedByTheUser(username) && isUnique(username) && !auth.user.emailVerified && <WaitingEmailVerificationModal/>}
      {/* <ReselectUsernameModal/> */} {/* NOTE ONLY ONE AT A TIME! */}
      <AppBar position="static">
        {/* <Toolbar variant={windowSmallDimension > 500 ? "dense" : "regular"}> */}
        <Toolbar variant="regular">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {auth.user && isSelectedByTheUser(username) ?
            <UserInfo/>
            :
            <LogModals/>
          }
          </Toolbar>
      </AppBar>
    </Box>
  );
}