import { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box'
import ModalBase from './ModalBase';
import ChooseUsernameForm from './ChooseUsernameForm';
import { Typography } from '@mui/material';
import { createNotCheckedUsername } from '../utils/username';
import { useAuth } from '../hooks/useAuth';
import { navResetFields, useNavBarContext } from './NavBarContext';

export default function SelectUsernameModal({type}) {
  const auth = useAuth();
  const [open, setOpen] = useState(true);
  const [navState, navDispatch] = useNavBarContext();

  useEffect(() => {
    navDispatch(navResetFields());
  }, [navDispatch, type])

  const handleSubmit = async() => {
    await auth.updateDisplayName(
      createNotCheckedUsername(navState.username.value)
    )
    await auth.checkUsername(navState.username.value)
  }

  const handleClose = useCallback(() =>{
    setOpen(false);
  }, [])

  return (
    <ModalBase open={open} onClose={handleClose} forceOpen={true}>
      <Box sx={{ width:'min(75vw, 400px)'}}>
        <Box sx={{ mt: 3, mb: 3, width:'100%', textAlign: 'center'}}>
          {type === "reselect"
            ? <Typography>Unfortunately someone just chose the same username as you... Please choose your username again</Typography>
            : <Typography>Choose your username</Typography>
          }
        </Box>
        <ChooseUsernameForm
          handleSubmit={handleSubmit}
        />
      </Box>
    </ModalBase>
  );
}