import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-identicon-sprites';

const SeedAvatar = ({seed}) => {
  const result = createAvatar(style, {seed});
  return (
    <div style={{width:'100%', height:'100%'}} dangerouslySetInnerHTML={{__html:result}}></div>
  )
};

export default SeedAvatar;