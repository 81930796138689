import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { isValidEmail } from '../utils/inputValidator';

const ForgotPasswordModal = ({handleClose}) => {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState('');

  const handleSend = async (e) => {
    e.preventDefault();
    const validationResult = isValidEmail(email);
    if (validationResult.error) {
      setError(validationResult.messages.join(', '))
      return;
    }
    const result = await auth.authSendPasswordResetEmail(email);
    if (!result.success){
      setError(result.errorReadable);
      return;
    }
    setShowProgress(true);
    setTimeout(handleClose, 3000);
  }

  return (
    <>
      <Box sx={{width:'min(75vw, 300px)', mt: 8, mb: 2, textAlign: 'center'}}>
        {!showProgress &&
          <form noValidate autoComplete="off" onSubmit={handleSend}>
            <TextField
              autoFocus
              sx={{ width: '26ch' }}
              id="outlined-required"
              label="Get a password recovery link"
              spellCheck="false"
              helperText="Enter your email"
              color={error !== '' ? 'error' : 'secondary'}
              onFocus={() => setError('')}
              onChange={(event) => {setError(''); setEmail(event.target.value)}}
            />
            <Box sx={{ mt: 2 }}>
              <div>
                <Button
                  disabled={email === ''}
                  variant="contained"
                  type="submit"
                  color={error !== '' ? 'error' : 'secondary'}
                >
                Send
                </Button>
              </div>
              {error !== '' && <Typography color="error" sx={{mt:2}}>{error}</Typography>}
            </Box>
          </form>
        }
        {showProgress &&
          <>
            <CircularProgress color="secondary"/>
            <Typography sx={{mt:2}} >Sending password recovery email</Typography>
          </>
        }
      </Box>
    </>
  )
}

export default ForgotPasswordModal;