import { createContext, useContext, useEffect, useReducer } from "react";
import { isPotentiallyValidUsername, isValidEmail, isValidPassword, isValidUsername } from "../utils/inputValidator";

export type Action =
  | {
    type: "SET_LEGAL";
    payload: Legal;
    }
  | {
    type: "RESET_FIELDS";
    payload: string;
    }
  | {
      type: "SET_USERNAME";
      payload: string;
    }
  | {
      type: "USERNAME_NOT_AVAILABLE";
      payload: string;
    }
  | {
      type: "SET_EMAIL";
      payload: string;
    }
  | {
      type: "SET_PASSWORD";
      payload: string;
    }
  | {
    type: "SET_STATUS";
    payload: Status;
    };

export type Legal = null | { 
  companyName: string;
  websiteName: string;
  websiteUrl: string;
  companyEmail: string;
};

export type Status = "idle" | "checkingUsername";

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_LEGAL":
      return {
        ...state,
        legal: action.payload,
      };
    case "RESET_FIELDS":
      return {
        ...state, // keeps legal
        username: initialState.username,
        email: initialState.email,
        password: initialState.password,
      }
    case "SET_USERNAME":
      const username = action.payload;
      const hasBeenPotentiallyValid = !state.username.hasBeenPotentiallyValid
        ? isPotentiallyValidUsername(username)
        : true
      ;
      const usernameInfo = isValidUsername(username);
      return {
        ...state,
        username:  {
          value: username,
          hasBeenPotentiallyValid,
          error: usernameInfo.error,
          messages: usernameInfo.messages,
        },
      };
    case "USERNAME_NOT_AVAILABLE":
      return {
        ...state,
        username:  {
          ...state.username,
          error: true,
          messages: [...state.username.messages, "Username not available"],
        },
      };
    case "SET_EMAIL":
      const email = action.payload;
      const emailInfo = isValidEmail(email);
      return {
        ...state,
        email: {
          value: email,
          error: emailInfo.error,
          messages: emailInfo.messages,
        }
      };
    case "SET_PASSWORD":
      const password = action.payload;
      const passwordInfo = isValidPassword(password);
      return {
        ...state,
        password: {
          value: password,
          error: passwordInfo.error,
          messages: passwordInfo.messages,
        }
      };
    case "SET_STATUS":
      const status = action.payload;
      return {
        ...state,
        status,
      };
    default:
      return state;
  }
}

export type State = {
  username: {
    value: string,
    hasBeenPotentiallyValid: boolean,
    error: boolean,
    messages: string[],
  },
  email: {
    value: string,
    error: boolean,
    messages: string[],
  },
  password: {
    value: string,
    error: boolean,
    messages: string[],
  },
  legal: Legal,
  status: Status,
};

const initialState : State = {
  username: {
    value: '',
    hasBeenPotentiallyValid: false,
    error: false,
    messages: [],
  },
  email: {
    value: '',
    error: false,
    messages: [],
  },
  password: {
    value: '',
    error: false,
    messages: [],
  },
  legal: null,
  status: "idle",
};

const NavBarContext = createContext<[State, React.Dispatch<Action>]>([
  initialState,
  () => initialState
]);

export const ProvideNavBarContext = ({children, legal} : {children : React.ReactNode, legal : Legal}) => {
  const [navState, navDispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (legal !== null) {
      navDispatch(setLegal(legal));
    }
  },[legal])
  return (
    <NavBarContext.Provider value={[navState, navDispatch]}>
      {children}
    </NavBarContext.Provider>
  );
}
export const useNavBarContext = () => useContext(NavBarContext);

export const setLegal = (legal : Legal) : Action => {
  return { type: "SET_LEGAL", payload: legal };
};

export const navResetFields = (): Action => {
  return { type: "RESET_FIELDS", payload: "" };
};

export const setUsername = (username : string): Action => {
  return { type: "SET_USERNAME", payload: username };
};

export const usernameNotAvailable = (): Action => {
  return { type: "USERNAME_NOT_AVAILABLE", payload: "" };
};

export const setEmail = (email : string): Action => {
  return { type: "SET_EMAIL", payload: email };
};

export const setPassword = (password : string): Action => {
  return { type: "SET_PASSWORD", payload: password };
};

export const setStatus = (status : Status): Action => {
  return { type: "SET_STATUS", payload: status };
};