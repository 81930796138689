import Markdown from "./Markdown";
import AuthBar from './AuthBar';

// TODO enable App Check https://firebase.google.com/docs/app-check/web/recaptcha-provider
// if you start using
// - Realtime Database
// - Cloud Storage
// - Cloud Functions (callable functions)
// Or if they start supporting Firestore / auth

const firebaseConfig = {
  apiKey: "AIzaSyBISA5nldo34dtcbEmlIf2zQkCna2q0XkM",
  authDomain: "fir-auth-190d3.firebaseapp.com",
  projectId: "fir-auth-190d3",
  storageBucket: "fir-auth-190d3.appspot.com",
  messagingSenderId: "560499356247",
  appId: "1:560499356247:web:066394ab9be51f2a592912"
};
// Remember to add authorized domain also
// if using other hosting provider!

const legal = {
  companyName: "COMPANY_NAME",
  websiteName: "WEBSITE_NAME",
  websiteUrl: "WEBSITE_URL",
  companyEmail: "COMPANY_EMAIL"
}

const palette = {
  primary: {
    main: '#FFFFFF',
    light: '#EFF2F3',
    dark: '#A5A7A8',
    contrastText: '#212121',
  },
  secondary: {
    main: '#26A69A',
    light: '#51B7AE',
    dark: '#1A746B',
    contrastText: '#FFFFFF',
  },
};

const App = () => {
  return (
    <AuthBar title="FirebaseAuth" firebaseConfig={firebaseConfig} legal={legal} palette={palette}>  {/* palette is optional, legal can be null */}
      <div style={{
        width:'100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
      }}>
        <div
          className="content" 
        >
          <Markdown/>
        </div>
      </div>
    </AuthBar>
  );
}

export default App;
