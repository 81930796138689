import isAlphanumeric from 'validator/lib/isAlphanumeric';
import isLength from 'validator/lib/isLength';
import isEmail from 'validator/lib/isEmail';
import { isNotChecked, isNotUnique, isUnique } from './username';

const USERNAME_MIN_LEN = 3; // These are also in firebase rules

export const isPotentiallyValidUsername = (username : string) : boolean => {
  return typeof username === 'string' && isLength(username, {min: USERNAME_MIN_LEN});
}

type Validity = {
  error: boolean,
  messages: string[],
}

// KEEP CONSISTENT WITH functions/index.ts!!!
export const isValidUsername = (username : string) : Validity => {
  const errorMessages : string[] = [];

  const newError = (message : string) : void => void errorMessages.push(message);

  if (!isLength(username, {min: USERNAME_MIN_LEN, max: 20})) {
    newError("Length must be between 3 and 20")
  }

  if (
    username.length > 0 && (
      !isAlphanumeric(username.replace(/-/g, '')) ||
      username.includes('--')
    )
  ) {
    newError("Only alphanumeric characters or single hyphens allowed")
  }

  if (
    username.length > 0 && (
      username.startsWith('-') ||
      username.endsWith('-')
    )
  ) {
    newError("Cannot begin or end with a hyphen")
  }

  if (
    isNotChecked(username) ||
    isUnique(username) ||
    isNotUnique(username)
  ) {
    newError("Illegal username start")
  }

  return {error: errorMessages.length > 0, messages: errorMessages};
}

export const isValidEmail = (email : string) : Validity => {
  const isValid = isEmail(email); 
  return {error: !isValid, messages: isValid ? [] : ["Not a valid email"]};
}

export const isValidPassword = (password : string) : Validity => {
  const isValid = isLength(password, {min: 6}); // Do not change FirebaseAuthWeakPasswordException == 6 https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuthWeakPasswordException
  return {error: !isValid , messages: isValid ? [] : ["Length must be at least 6 characters"]};
}
